import { graphql, Link } from "gatsby";
import React, { useState } from 'react';
import Layout from "../components/layout";
import Matrix from "../components/matrix";
import Card from "../components/newComponents/card";
import SectionAreas from "../components/newComponents/sectionAreas";
import SectionHead from "../components/newComponents/sectionHead";
import SectionListing from "../components/newComponents/sectionListing";
import SectionNav from "../components/newComponents/sectionNav";
import SectionRichText from "../components/newComponents/sectionRichText";
import Seo from "../components/seo";
import EditLink from "../components/newComponents/editLink"
import ProjectList from "../components/newComponents/projectList";
import ImgixFilePreview from "../components/newComponents/ImgixFilePreview";
import YoutubeEmbed from "../components/newComponents/youtubeEmbed";
import GeneralSection from "../components/newComponents/generalSection";
import { CgSoftwareDownload } from 'react-icons/cg';
import StaffList from "../components/newComponents/staffList";
import ImgixFluidImage from "../components/newComponents/ImgixFluidImage";

export const query = graphql`
  query WhatWeDoEntryQuery($slug: String!) {
    entry: craftWhatWeDoWhatWeDoEntry(slug: { eq: $slug }) {
      id
      remoteId
      sectionHandle
      title
      summary
      featureImage {
        ... AssetFragment
      }
      whatWeDoFeatureVideo {
        title
        url
        aspectRatio
      }
      whatWeDoFeatureBrochure {
        url
        title
        size
        height
        kind
        width
      }
      whatWeDoBrochureLink
      whatWeDoBrochureAspectRatio
      whatWeDoFeatureBrochure2 {
        url
        title
        size
        height
        kind
        width
      }
      whatWeDoBrochureLink2
      whatWeDoBrochureAspectRatio2
      bodyContent {
        ...BodyContentFragment
      }
      useReadMore
      whatWeDoAdditionalInfo {
        ...AdditionalInfoContentFragment
      }
      relatedAreasOfExpertiseFocusAreas {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          displayTitle
          summary
          uri
          featureImage {
            ... AssetFragment
          }
        }
      }
      relatedAreasOfExpertise {
        ... on Craft_areaOfExpertise_areaOfExpertise_Entry {
          slug
          title
          uri
        }
      }
      relatedProjects {
        ... on Craft_projects_projects_Entry {
          title
          summary
          uri
          featureImage {
            ... AssetFragment
          }
        }
      }
      relatedTeamMembers {
        ... on Craft_staff_staff_Entry {
          slug
        }
      }
      teamLeads {
        ... on Craft_staff_staff_Entry {
          id
          remoteId
          title
          displayTitle
          position
          sectionId
          sectionHandle
          remoteId
          slug
          uri
          featureImage {
            ...AssetFragment
          }
          bodyContent {
            ...BodyContentFragment
          }
        }
      }
      relatedFocusArea {
        ... on Craft_whatWeDo_whatWeDo_Entry {
          displayTitle
          summary
          uri
          featureImage {
            ... AssetFragment
          }
        }
      }
      showCustomBanner
      customBannerHeadline
      customBannerRichText
      customBannerImage {
        ... AssetFragment
      }
      customBannerLink
      secondaryBannerImage {
        ... AssetFragment
      }
      secondarybannerlink
    }

    relatedFocusAreaProjects: allCraftProjectsProjectsEntry(filter: {relatedFocusArea: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
      }
    }

    relatedAreaOfExpertiseProjects: allCraftProjectsProjectsEntry(filter: {relatedAreaOfExpertise: {elemMatch: {slug: {eq: $slug}}}} sort: {order: DESC, fields: postDate}) {
      nodes {
        id
      }
    }
  }
`;

function WhatWeDoOverviewEntry({ data, pageContext }) {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout pageContext={pageContext}>
      <Seo title={data.entry.title} />
      <div className="main">
        <GeneralSection className="hero-sm">
        <SectionHead image={data.entry.featureImage[0]}/>
            <header className="section__head relative">
              <h1 className="hero__title absolute inset-0" style={{}}>
                {data.entry.title}
              </h1>
            </header>
            <SectionNav data={data} base={`/what-we-do/${pageContext.slug}`} currentUri={`/what-we-do/${pageContext.slug}`} />
        </GeneralSection>
        <GeneralSection className="section-overview">
          <SectionHead title={data.entry.title} subtitle={"Overview"}/>
          {data.entry.useReadMore ? (isOpen ? <Matrix blocks={data.entry.bodyContent} /> : <SectionRichText className="prose max-w-none" richText={data.entry.summary}/>) : <Matrix blocks={data.entry.bodyContent} />}
          {data.entry.bodyContent.length > 0 && !isOpen && data.entry.useReadMore && <button className="section__link link-more text-gray-3" onClick={() => setIsOpen(true)}>Read more</button>}
        </GeneralSection>
        {data.entry.whatWeDoAdditionalInfo.length > 0 &&
          <GeneralSection className="section-overview">
            <SectionHead subtitle={"Additional info"}/>
            <Matrix blocks={data.entry.whatWeDoAdditionalInfo}/>
         </GeneralSection>
        }
        <section>
          <div className="shell">
            <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-2 md:grid-rows-1 gap-2 mb-4">
              {data.entry.whatWeDoFeatureVideo.url ? <div><YoutubeEmbed title={data.entry.whatWeDoFeatureVideo.title} url={data.entry.whatWeDoFeatureVideo.url}/></div> : ''}
              {data.entry.whatWeDoBrochureLink ? 
                <figure>
                  <iframe title="Brochure" style={{"border":"1px solid #777"}} src={data.entry.whatWeDoBrochureLink} width="100%" height="100%" frameBorder={0} allowFullScreen />
                </figure>
                :
                data.entry.whatWeDoFeatureBrochure[0] && 
                  <div className={data.entry.whatWeDoFeatureBrochure[0] ? "relative border-gray-300 border-solid border mx-auto" : null} style={data.entry.whatWeDoBrochureAspectRatio === "portrait" ? { "aspect-ratio": "1/1.4142", "height": "316px" } : null}>
                      <a href={data.entry.whatWeDoFeatureBrochure[0].url} target="_blank" rel="noreferrer">
                        <ImgixFilePreview fit="fill" fileUrl={data.entry.whatWeDoFeatureBrochure[0].url} height="" sizes='(min-width: 800px) 800px, 1200px' ar={data.entry.whatWeDoBrochureAspectRatio === "portrait" ? 1/1.4142 : 16/9} />
                        <span className="absolute  top-0 left-0 w-full h-full flex justify-center items-center">
                          <CgSoftwareDownload className="w-12 h-12 text-white" style={{filter: "drop-shadow( 3px 3px 5px rgba(0, 0, 0, .9))"}} alt=""/>
                        </span>
                      </a>
                  </div>
              }
              {data.entry.whatWeDoBrochureLink2 ? 
                <figure>
                  <iframe title="Brochure" style={{"border":"1px solid #777"}} src={data.entry.whatWeDoBrochureLink2} width="100%" height="100%" frameBorder={0} allowFullScreen />
                </figure>
                :
                data.entry.whatWeDoFeatureBrochure2[0] && 
                  <div className={data.entry.whatWeDoFeatureBrochure2[0] ? "relative border-gray-300 border-solid border mx-auto" : null} style={data.entry.whatWeDoBrochureAspectRatio2 === "portrait" ? { "aspect-ratio": "1/1.4142", "height": "316px" } : null}>
                      <a href={data.entry.whatWeDoFeatureBrochure2[0].url} target="_blank" rel="noreferrer">
                        <ImgixFilePreview fit="fill" fileUrl={data.entry.whatWeDoFeatureBrochure2[0].url} height="" sizes='(min-width: 800px) 800px, 1200px' ar={data.entry.whatWeDoBrochureAspectRatio2 === "portrait" ? 1/1.4142 : 16/9} />
                        <span className="absolute  top-0 left-0 w-full h-full flex justify-center items-center">
                          <CgSoftwareDownload className="w-12 h-12 text-white" style={{filter: "drop-shadow( 3px 3px 5px rgba(0, 0, 0, .9))"}} alt=""/>
                        </span>
                      </a>
                  </div>
              }
            </div>
          </div>
        </section>

        {data.entry.teamLeads.length > 0 &&
          <SectionListing title={"Leading The Team"} primaryBackground={true} dataId={1}>
            <StaffList staff={data.entry.teamLeads} keyBase={"team-leads-listing"} />
          </SectionListing>
        }

        {data.entry.showCustomBanner &&
          <GeneralSection className="financial-banner py-2">
            <SectionHead subtitle={data.entry.customBannerHeadline}/>
            <div className="block md:grid md:grid-cols-2 py-3 items-start md:items-center">
              <div className="border-0 md:border-r-4 financial-banner-line py-4">
                <a href={data.entry.customBannerLink} className="section__title title h2 text-gray">{data.entry.customBannerRichText}</a>
              </div>
              <a href={data.entry.customBannerLink} className="py-4">
                <ImgixFluidImage attribution={data.entry.customBannerImage[0].attribution} imageUrl={data.entry.customBannerImage[0].url} sizes="50vw" ar={380/89} focalPoint={data.entry.customBannerImage[0].focalPoint}/>
              </a>  
            </div>
          </GeneralSection>
        }

        {data.entry.secondaryBannerImage[0] &&
          <GeneralSection className="pb-6">
            <a href={data.entry.secondarybannerlink} target="_blank" rel="noreferrer">
              <SectionHead image={data.entry.secondaryBannerImage[0]} ar={1486/748}/>
            </a>
          </GeneralSection>
        }

        {(data.entry.relatedAreasOfExpertiseFocusAreas.length > 0 || data.entry.relatedAreasOfExpertise.length > 0) &&
          <SectionAreas>
            {data.entry.relatedAreasOfExpertiseFocusAreas.length > 0 &&
              <div className="boxes swiper-container js-load-more js-slider-boxes-projects">
                <ul>
                  {data.entry.relatedAreasOfExpertiseFocusAreas.map((item, i) => (
                    <Card 
                      key={`what-we-do-entry-area-of-expertise-focus-areas-${i}`} 
                      image={item.featureImage[0]} 
                      title={item.displayTitle} 
                      subtitle={item.summary} 
                      href={`/${item.uri}`}
                      color={2}
                    />
                  ))}
                </ul>
                <div className="swiper-pagination boxes__pagination" />
              </div>
            }
            {data.entry.relatedAreasOfExpertise.length > 0 && 
              data.entry.relatedAreasOfExpertise.map((areaOfExpertise, i) => (
                <li key={`what-we-do-entry-areas-of-expertise-${i}`}>
                  <Link to={`/what-we-do/${pageContext.slug}/areas-of-expertise#id-${areaOfExpertise.slug}`}>{areaOfExpertise.title}</Link>
                </li>
              ))
            } 
          </SectionAreas>
        }        

        {data.entry.relatedProjects.length > 0 &&
          <SectionListing title={"Projects"} dataId={1} primaryBackground={true} href={data.relatedFocusAreaProjects.nodes.length > 0 || data.relatedAreaOfExpertiseProjects.nodes.length > 0 ? `/what-we-do/${pageContext.slug}/projects` : false} hrefLabel={"View all"}>
            <ProjectList projects={data.entry.relatedProjects} keyBase="what-we-do-entry-projects"/>
          </SectionListing>
        }

        {data.entry.relatedFocusArea.length > 0 &&
          <SectionListing title={"Related Focus Areas"} dataId={1} >
            {data.entry.relatedFocusArea.map((focusArea, i) => (
              <Card key={`what-we-do-entry-focus-areas-${i}`} image={focusArea.featureImage[0]} title={focusArea.displayTitle} subtitle={focusArea.summary} href={`/${focusArea.uri}`} color={2}/>
            ))}
          </SectionListing>
        }
      </div>

      <EditLink
        remoteId={data.entry.remoteId}
        sectionHandle={data.entry.sectionHandle}
      />
    </Layout> 
  );
};

export default WhatWeDoOverviewEntry;
